<template>
    <div v-if="is_mounted" class="q-pa-lg">
        <br>
        <div align="center">
            <span class="text-h4">Rinnovo Contratto<br>

            </span>
        </div>
        <br>
        <div class="row">

            <div class="col-12 col-md-4 justify-center">
                <fieldset>
                    <legend><h5 class="q-mt-sm">Dati del Contratto</h5></legend>
                    <table class="table">
                        <tbody>
                            <tr>
                                <td class="domanda">Contraente</td>
                                <td class="risposta">{{ dati_cliente.nominativo.toUpperCase() }}</td>
                            </tr>
                            <tr>
                                <td class="domanda">Tipo di Contratto</td>
                                <td class="risposta">{{ getTipoContratto }}</td>
                            </tr>
                            <tr>
                                <td class="domanda">Fornitore</td>
                                <td class="risposta">{{ getPropostaAttiva.fornitore }}</td>
                            </tr>
                            <tr>
                                <td class="domanda">Tariffa</td>
                                <td class="risposta">{{ getTariffa }}</td>
                            </tr>
                        </tbody>
                    </table>
                </fieldset>
                <br>

                <fieldset class="q-mb-md q-mt-sm">

                    <div class="row">
                        <div class="cl-12 col-md-8" v-html="domanda_label">

                        </div>
                        <div class="cl-12 col-md-4" align="center">
                            <q-option-group
                                :options="domanda_analisi"
                                :label="domanda_analisi.label"
                                type="radio"
                                v-model="is_changed"
                                @input="$emit('qq-domanda-change')"
                                />
                        </div>
                    </div>
                </fieldset>

            </div>

            <div class="col-12 col-md-8 justify-center">

                <fieldset>
                    <legend><h5 class="q-mt-sm">Analisi necessit&agrave;</h5></legend>

                    <QQQuestionario
                        v-if="is_changed"
                        :domande="analisi_necessita"
                        v-model="analisi_necessita"
                        @error-checked="onErrorChecked($event)"
                        @qq-questionario-change="onDomandaChanges($event)"
                    />

                    <div v-if="!is_changed">
                        <table class="table">
                            <tbody>
                                <tr v-for="(domanda,index) in analisi_necessita"
                                    v-bind:key="index">
                                    <td class="domanda">{{domanda.label}} </td>
                                    <td class="risposta">{{ analizzaRisposta(domanda) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </fieldset>
                <br>
            </div>

            <div class="col-12 col-md-12 justify-center"><hr></div>

        </div>

        <div class="row justify-center">

            <div class="col-md-4" align="center">
                <QQButton label="INDIETRO" color="blue-grey" icon="undo" size="md"
                    @click.native="gotoStart()"
                />
            </div>
            <div class="col-md-4" align="center">
                <QQButton label="AVANTI"
                    color="blue-grey"
                    icon="mdi-arrow-right-bold"
                    size="md"
                    icon_align="right"
                    @click.native="onAvantiClicked"
                />
            </div>
        </div>

        <br><br><br><br><br>
    </div>
</template>

<script>
    import QQButton from "@/components/QQButton.vue";
    //import policy from "@/libs/policy.js";
    import { mapFields } from "vuex-map-fields";
    import QQQuestionario from '@/components/QQQuestionario.vue';
    import { mapState, mapActions } from "vuex";
    //import store from "@/store";
    import commonLib from "@/libs/commonLib.js";

    export default {
        name: "AnalisiNecessita",
        data() {
            return {
                is_mounted: false,
                is_quotazione: false,
                is_acquistabile: false,
                quotazione: {},
                dati_caricati: false,
                is_quotazione_rinnovo: false,
                is_loading: false,
                is_changed: "",
                domanda_analisi: [
                    { label: "Si", value: true },
                    { label: "No", value: false }
                ],
                domanda_label: "<br>I dati del questionario di analisi necessita` sono cambiati?",
                dati_cliente: {}
            }
        },
        components: {
            QQButton,
            QQQuestionario
        },
        computed: {
            ...mapState({
                formPreventivo: state => state.formPreventivo,
                id_preventivo: state=>state.formPreventivo.preventivo.id,
                analisi_necessita: state=>state.formPreventivo.preventivo.analisi_necessita
            }),
            ...mapFields('gestioneRinnovi', [
                "forza_taylormade"
            ]),
            getTipoContratto() {
                var prodotto = this.formPreventivo.preventivo.prodotti.find(p => {
                    return p.is_checked;
                });

                if (commonLib.isEmpty(prodotto)) return "";

                return prodotto.label;
            },
            getPropostaAttiva() {
                var prodotto = this.formPreventivo.preventivo.prodotti.find(p => {
                    return p.is_checked;
                });

                var proposta = prodotto.proposte.find(p => {
                    return p.is_checked;
                });

                if (commonLib.isEmpty(proposta)) return {};

                return proposta;
            },
            getTariffa() {
                var proposta = this.getPropostaAttiva;
                return proposta.tariffa.replace("TaylorMade","");
            }
        },
        methods: {
            ...mapActions({
                fetchDatiCliente: "gestioneClienti/fetchDatiCliente",
                fetchConvertiInTaylormade: "gestioneRinnovi/fetchConvertiInTaylormade"
            }),
            gotoStart() {
                this.$router.push({name : "Preventivi.MenuGestionePreventivi"});
            },
            onDomandaChanges() {
                this.forza_taylormade = true;
            },
            async onAvantiClicked() {
                if (this.is_changed === "") {
                    this.$q.dialog({
                        title: 'Attenzione',
                        message:  "Prima di andare avanti devi selezionare se ci sono stati cambiamenti sul questionario di analisi delle necessita`",
                    });

                    return;
                }

                if (this.forza_taylormade)
                    await this.fetchConvertiInTaylormade(this.formPreventivo);
                console.log("Ci siamo");
                this.$router.push({name : "Rinnovi.AnalisiRischio"});
            },
            onErrorChecked(event) {
                console.log(event);
            },
            analizzaRisposta(parametro) {
                let result = "";

                if (parametro.type === "checkbox") {
                    return parametro.is_checked ? "Si" : "No";
                }

                var value = parametro.value;
                var options = parametro.options;

                // Cerca la label tra le options in base alla risposta inserita
                let element = options.filter(elemento => {
                    return elemento.value === value;
                })
                if (element.length === 1) {
                    value = element[0].label;
                }

                if ((value !== null) && (Array.isArray(value))) {
                    value.forEach(valore => {
                        result += '"'+valore+'", ';
                    });
                    result = result.slice(0, -2);

                    return result.replace("_"," ");
                } else {

                    if (value !== null) {
                        return value.replace("_"," ");
                    } else
                        return "";
                }


            },
        },
        async mounted() {
            this.is_mounted = false;
            this.forza_taylormade = false;
            console.log(this.formPreventivo);
            this.dati_cliente = await this.fetchDatiCliente(this.formPreventivo.IDCliente);
            console.log("Dati cliente:",this.dati_cliente);
            this.is_mounted = true;
        }
    }
</script>

<style scoped>
    td.domanda {
        text-align: left;
        font-style: italic;
        border-bottom: 1px dashed #c0c0c0;
    }
    td.risposta {
        border-bottom: 1px SOLID #c0c0c0;
        padding: 6px;
        width: 60%;
        font-weight: bold;
        vertical-align: bottom;
    }
    .table {
        width: 100%;
    }
    .table-bordered {
        border-collapse: collapse;
        width: 100%;
    }

    .table-bordered tbody tr td, .table tr th {
        /*border: 1px solid #404040;*/
        border: 1px solid;
        padding: 8px;
    }

    .table-bordered thead tr th {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 8px;
        text-align: left;
        /*color: #404040;
        background-color: #D0D0D0;*/
        border: 1px SOLID #404040;
        background-color: #ffcc99;
        color: #000;
    }

    .table-bordered tFoot tr td {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 8px;
        padding-right: 8px;
        text-align: right;
        border: 1px SOLID;

    }
</style>
